import React from "react";

function ContactUs() {
  return (
    <>
      <h3 class="bp3-heading">Contact Us</h3>
      <ul>
        <li>
          <a href="mailto:admin@congress.dev">Admin</a>
        </li>
      </ul>
    </>
  );
}

export default ContactUs;
